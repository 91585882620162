import { connect } from 'react-redux'
import { attachEmployees } from 'redux/actions/payrolls'
import { getPayrollByIdWithEmployeeIds } from '../../../../../selectors/payroll'
import { invalidatePayrollInstanceEmployeePivot } from 'redux/actions/payrollInstanceEmployeePivot'
import EmployeeAttachDetachHOC from 'containers/employee/EmployeeAttachDetachHOC'
import Fetcher from 'containers/Fetcher'
import PayrollPaygroupEmployeesContainer from './PayrollPaygroupEmployeesContainer'
import { getFilteredEmployeesWithCompanyAndBusinessUnit } from 'redux/selectors/employees'
import { buildFilterName } from 'redux/filters/commonFilters'
import { fetchEmployeeSystemUsers } from 'redux/actions/employeeSystemUsers'
import attachDettachEmployeesFilter, { prepareAttachDettachEmployeeFilters } from 'redux/filters/attachDettachEmployeesFilter'

const limit = 24

const mapDispatchToProps = (dispatch) => ({ dispatch })

// map to state
const mapStateToProps = (state, props) => {
  const payrollId = props.match.params.payrollId
  // get selected payroll
  let payroll = getPayrollByIdWithEmployeeIds(state, { payrollId })

  const isOnceOffPayroll = payroll.frequency === 'Once off'
  const messageContent = isOnceOffPayroll
    ? `This/These employees will be assigned to ${payroll.name}.
     Values for which groups of elements should be present in this Once Off Payrun File`
    : `Are you sure you want to assign this/these employee/s to ${payroll.name}?`

  const employees = getFilteredEmployeesWithCompanyAndBusinessUnit(state, { filter: props.filters.employeeSystemUsers.name })

  const filterName = buildFilterName({ payrollId }).assignEmployeesPayroll

  const {
    totalCount,
    params: { offset, limit },
  } = state.employeeSystemUsers.filters[filterName]

  return {
    state,
    employees,
    totalPages: Math.ceil(totalCount / limit),
    currentPage: offset / limit + 1,
    hidePagination: totalCount <= limit,
    content: messageContent,
    buttonName: 'Assign',
    payrollInstancePayPeriod: payroll.frequency,
    companyId: payroll.company,
    payrollName: payroll.name,
    payrollId: payroll.id,
    initialValues: {
      payrollId: payroll.id,
    },
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { companyId, state, payrollId } = stateProps

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    toggleAll: (value, employees) => ownProps.updateEmployees('payrollAttachEmployees', employees, value),
    onSubmit: (data) =>
      dispatch(attachEmployees(data)).then(() => {
        dispatch(invalidatePayrollInstanceEmployeePivot())

        // On successful sync, go back to the company payroll page
        const path = `/companies/${companyId}/payroll`
        ownProps.history.push(path)
      }),
    onPageChange: (page) => {
      const filter = prepareAttachDettachEmployeeFilters({
        page,
        limit,
        filters: {
          ...ownProps.filters.employeeSystemUsers.params,
          attachableToPayroll: payrollId,
        },
        state,
        filterName: buildFilterName({ payrollId }).assignEmployeesPayroll,
      })
      dispatch(fetchEmployeeSystemUsers({ filter, disableObsoleteFlow: true }))
    },
  }
}

const Container = EmployeeAttachDetachHOC(connect(mapStateToProps, mapDispatchToProps, mergeProps)(PayrollPaygroupEmployeesContainer))

export default Fetcher(Container, [
  'payrolls',
  attachDettachEmployeesFilter({
    limit,
    filterKey: 'assignEmployeesPayroll',
  }),
])
