import Fetcher from 'containers/Fetcher'
import { connect } from 'react-redux'
import { detachEmployees } from 'redux/actions/paygroups'
import { getPaygroupByIdWithEmployeeIds } from '../../../../../selectors/paygroup'
import { showMessage } from 'redux/actions/modal'
import { invalidatePayrollInstanceEmployeePivot } from 'redux/actions/payrollInstanceEmployeePivot'
import EmployeeAttachDetachHOC from 'containers/employee/EmployeeAttachDetachHOC'
import PayrollPaygroupEmployeesContainer from './PayrollPaygroupEmployeesContainer'
import { getFilteredEmployeesWithCompanyAndBusinessUnit } from 'redux/selectors/employees'
import { buildFilterName } from 'redux/filters/commonFilters'
import { fetchEmployeeSystemUsers } from 'redux/actions/employeeSystemUsers'
import attachDettachEmployeesFilter, { prepareAttachDettachEmployeeFilters } from 'redux/filters/attachDettachEmployeesFilter'

const limit = 24
// say what you're dispatching on init
const mapDispatchToProps = (dispatch) => ({ dispatch })

// map to state
const mapStateToProps = (state, props) => {
  const paygroupId = props.match.params.paygroupId

  // get selected payroll
  let paygroup = getPaygroupByIdWithEmployeeIds(state, { paygroupId })

  const employees = getFilteredEmployeesWithCompanyAndBusinessUnit(state, { filter: props.filters.employeeSystemUsers.name })

  const filterName = buildFilterName({ paygroupId }).unassignEmployeesPaygroup

  const {
    totalCount,
    params: { offset, limit },
  } = state.employeeSystemUsers.filters[filterName]

  return {
    state,
    employees,
    totalPages: Math.ceil(totalCount / limit),
    currentPage: offset / limit + 1,
    hidePagination: totalCount <= limit,
    companyId: paygroup.company,
    paygroupId,
    paygroupName: paygroup.name,
    employeesWithDifferTerms: [],
    content: `Are you sure you want to unassign this/these employee/s from ${paygroup.name}?`,
    buttonName: 'Remove',
    paygroup,
    initialValues: {
      paygroupId: paygroup.id,
    },
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { companyId, state, paygroupId, paygroupName } = stateProps

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    toggleAll: (value, employees) => ownProps.updateEmployees('paygroupDetachEmployees', employees, value),
    onSubmit: (data) =>
      dispatch(detachEmployees(data)).then(() => {
        dispatch(invalidatePayrollInstanceEmployeePivot())

        // On successful sync, go back to the company payroll page
        const path = `/companies/${companyId}/payroll`
        ownProps.history.push(path)
        // Display successful message
        dispatch(
          showMessage({
            body: `Employees successfully unassigned from Paygroup ${paygroupName}`,
          })
        )
      }),
    onPageChange: (page) => {
      const filter = prepareAttachDettachEmployeeFilters({
        page,
        limit,
        filters: {
          ...ownProps.filters.employeeSystemUsers.params,
        },
        state,
        filterName: buildFilterName({ paygroupId }).unassignEmployeesPaygroup,
      })
      dispatch(fetchEmployeeSystemUsers({ filter, disableObsoleteFlow: true }))
    },
  }
}

const Container = EmployeeAttachDetachHOC(connect(mapStateToProps, mapDispatchToProps, mergeProps)(PayrollPaygroupEmployeesContainer))

export default Fetcher(Container, [
  'paygroups',
  'companies',
  attachDettachEmployeesFilter({
    limit,
    filterKey: 'unassignEmployeesPaygroup',
  }),
])
