import React from 'react'
import { connect } from 'react-redux'
import { getPayrollInstanceByIdWithCurrentStep } from 'redux/selectors/payrollInstance'
import { hasAccess, isAccounting, isControlling, isCot, isFinanceAdmin, isPayrollAdmin, isPayrollAnalyst, isReadOnly } from 'redux/selectors/auth'
import { PayrollInstanceFileView } from '../components/PayrollInstanceFileView'
import Fetcher from 'containers/Fetcher'
import { createFilter } from 'utils/redux/filter'
import { showMessage } from 'redux/actions/modal'
import { triggerGlReportCreation, updatePayrollInstances } from 'redux/actions/payrollInstances'
import { errorToString } from 'utils/apiErrors'
import { isPayrollInstanceClosedOrWithExtraSteps } from 'utils/payrollInstanceStatuses'
import { triggerZeroTouch } from 'redux/actions/document'
import { payrunStatesEnum } from 'utils/enums/payrunStates'
import { createNewExportArchiveDownloadJob, createNewReportJob } from 'redux/actions/pollingJob'
import { getCompletedReportCreateJobs, getNonCompletedPayFileExportArchiveJobs } from 'redux/selectors/pollingJobs'
import { startToastFileGeneration } from 'redux/actions/toasts'
import ToastLoadingViews from 'components/ToastLoadingViews'
import { toast } from 'react-toastify'
import { getDocumentTypes } from 'redux/selectors/documentTypes'
import { fetchReports } from 'redux/actions/reports'
import { jobStatusEnum } from 'utils/enums/jobStatusEnum'
import { getUser } from 'redux/selectors/employees'
import { fetchPayrolls } from 'redux/actions/payrolls'

let modalState = {
  showConfirmUnlock: false,
  showConfirmLock: false,
  modalHeading: '',
  reimportGTNConfirmationModal: false,
  reimportGTNConfirmationWithPayrunLocked: false,
  successLockWithGTNReimport: false,
  documentId: null,
  documentName: null,
  documentVersion: null,
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createReport: (data) =>
      // eslint-disable-next-line no-undef
      dispatch(createNewReportJob(data, _, 'blueButton')).then((job) => {
        dispatch(startToastFileGeneration(job.id))
        toast.loading(<ToastLoadingViews job={job} />, {
          toastId: job.id,
          autoClose: true,
          closeOnClick: false,
          pauseOnHover: false,
        })
      }),
    downloadArchive: (id) => {
      return dispatch(createNewExportArchiveDownloadJob(id)).then((job) => {
        dispatch(startToastFileGeneration(job.id))
        toast.loading(<ToastLoadingViews job={job} />, { toastId: job.id, autoClose: false, closeOnClick: false })
      })
    },
    triggerGlReportCreation: (id) =>
      dispatch(triggerGlReportCreation(id)).then((res) => {
        if (res.success) {
          const body = (
            <div>
              <h2 className='u-margin-none u-text--huge u-weight--bold u-margin-bottom-small'>Your report is in progress</h2>
              <span>
                If your account is set up with GL Reporting functionality, then your report will be available in the GL tab shortly. <br />
                <br />
                If your account is not set up for this, and you would like GL Reporting functionality,
                <br />
                contact your Client Success Manager.
              </span>
            </div>
          )
          dispatch(showMessage({ body }))
        }
      }),
    onHandleSubmit: (data) =>
      dispatch(updatePayrollInstances(data)).then(
        (res) => {
          dispatch(fetchPayrolls())
          return res
        },
        // eslint-disable-next-line handle-callback-err
        (err) => {
          return { hasError: true }
        }
      ),
    onModalStateChange: ({
      showConfirmLock = false,
      showConfirmUnlock = false,
      heading = '',
      reimportGTNConfirmationModal = false,
      reimportGTNConfirmationWithPayrunLocked = false,
      successLockWithGTNReimport = false,
      documentId = null,
      documentName = null,
      documentVersion = null,
    }) => {
      modalState = {
        showConfirmUnlock: showConfirmUnlock,
        showConfirmLock: showConfirmLock,
        modalHeading: heading,
        reimportGTNConfirmationModal: reimportGTNConfirmationModal,
        reimportGTNConfirmationWithPayrunLocked: reimportGTNConfirmationWithPayrunLocked,
        documentId: documentId,
        documentName: documentName,
        documentVersion: documentVersion,
        successLockWithGTNReimport: successLockWithGTNReimport,
      }
    },
    onZeroTouchSubmit: (data) =>
      dispatch(triggerZeroTouch(data)).then(
        (res) => res,
        (err) =>
          dispatch(
            showMessage({
              body: errorToString(err) || 'Something went wrong.',
            })
          )
      ),
  }
}

const mapStateToProps = (state, { payrollInstanceId, history, ...props }) => {
  const canCreateGlReport =
    !isReadOnly(state) &&
    (isCot(state) || isPayrollAdmin(state) || isPayrollAnalyst(state) || isFinanceAdmin(state) || isAccounting(state) || isControlling(state))

  const user = !isCot(state) && getUser(state, { userId: state.auth.userId })
  const userHasAccessToChangeLogReport = isCot(state) || user.specialRight.accessChangeLogReport

  const payrollInstance = getPayrollInstanceByIdWithCurrentStep(state, { payrollInstanceId })
  const {
    isKU,
    isWithRightGtnReImport,
    isLastGtnMappingReportSuccessful,
    isPayrollInstanceLocked,
    isWithRightLockUnlock,
    isKUGlobalOwner,
    lastGtnDocument,
    isPayrollInstanceOnStepToLockUnlock,
  } = payrollInstance.newLockUnlock || {}

  const payrollInstanceIsClosedOrWithExtraSteps = isPayrollInstanceClosedOrWithExtraSteps(payrollInstance.status)
  const hasUserLockUnlockPermission = isWithRightLockUnlock || isKUGlobalOwner
  const hasRightsGTNReimportButton = isKUGlobalOwner || (isKU && isWithRightGtnReImport)
  const hasRightsToLockUnlock = isKUGlobalOwner || (isKU && isWithRightLockUnlock)
  const { id: docId, name: docName, version: docVersion } = lastGtnDocument || {}
  const inProgressPollingJobs = getNonCompletedPayFileExportArchiveJobs(state)
  const inProgressReportCreateJobs = getCompletedReportCreateJobs(state)
  const jobForPayfile = inProgressPollingJobs.find((job) => job.entityId === parseInt(payrollInstanceId, 10))
  const createReportJob = inProgressReportCreateJobs.find((job) => job)
  if (
    createReportJob?.status === jobStatusEnum.COMPLETED &&
    createReportJob?.entityId &&
    createReportJob?.filters?.filters?.payrollInstance?.[0] === payrollInstanceId
  ) {
    props.dispatch(fetchReports())
    history.push(`/reporting/${createReportJob.entityId}/details`)
  }

  return {
    payrollInstance,
    hasAccess: hasAccess(state)(['PAYFILE_VIEW']),
    allDocTypes: getDocumentTypes(state),
    canCreateGlReport,
    userHasAccessToChangeLogReport,
    modalState,
    isLastGtnMappingReportSuccessful,
    isPayrollInstanceLocked,
    payrollInstanceIsClosedOrWithExtraSteps,
    hasUserLockUnlockPermission,
    hasRightsGTNReimportButton,
    hasRightsToLockUnlock,
    isPayrollInstanceOnStepToLockUnlock,
    disablePayrunTimeAttendanceDownload: jobForPayfile,
    disableReportCreation: createReportJob,
    docId,
    docName,
    docVersion,
    areModalButtonsEnabled: payrollInstance.status !== payrunStatesEnum.INACTIVE,
  }
}

const makePayrollInstanceFilter = (name, filterName = 'payrollInstance') => ({
  name,
  params: [
    {
      _computed: {
        filter: (state, { payrollInstanceId, params }) =>
          createFilter({
            [filterName]: params && params.payrollInstanceId ? params.payrollInstanceId : payrollInstanceId,
          }),
      },
    },
  ],
})

const Component = connect(mapStateToProps, mapDispatchToProps)(PayrollInstanceFileView)

export default Fetcher(Component, [makePayrollInstanceFilter('payrollInstances', 'id'), makePayrollInstanceFilter('payrollInstanceStep')])
